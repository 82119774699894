import React from "react";

import { graphql, Link } from "gatsby";
import BackgroundImage from 'gatsby-background-image';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from 'gatsby-image';

const ExecutiveTeamCoaching = ({ data }) => {
  const title = "Teams and Groups";
  const imageData = data.file.childImageSharp.fluid;
  const infoGraphicFluid = data.infoGraphic.childImageSharp.fluid;
  return (
    <Layout headerClass="relative bg-white" bodyClass="px-0 md:px-0 lg:px-0 h-full growing">
      <SEO title={title} />

      <div
        className="min-h-screen h-full flex flex-col "
        
      >
        <BackgroundImage
        Tag="div" className="w-full h-40 sm:h-32 lg:h-56"  fluid={imageData}>


          <div className="container mx-auto px-4 sm:px-2 md:px-20 lg:px-24 mb-auto">
            <div className="w-7/12 mt-10 sm:mt-12 lg:mt-20 font-heading self-start">
              <h1 className="text-4xl md:text-3xl lg:text-6xl text-pink-600 leading-tight text-shadow-xl">{title}</h1>
            </div>
            </div>
            

        </BackgroundImage>
        <div className="container mx-auto pb-10  md:px-20 lg:px-56 px-6">
            <div className="mt-6 p-1 bg-teal-600 heropattern-texture-purple-900"> 
              <div className="text-base  self-center m-4" >
                <p className="text-white text-shadow-sm">Coaching enables lasting change by providing an environment where individuals can explore how they interact, and their impact, within a team </p>
              </div>
            </div>
            <div className="text-base text-teal-900">
              <ul className="p-5 list-disc">
                <li>Team coaching builds and develops effective teams with a strong business culture that
                   consistently deliver results through building measurable, scalable programs and initiatives 
                   that translates the organisational goals into a meaningful team strategy they can all support
                </li>
                <li>By focusing on the key attributes of resilient high performing teams, the emphasis shifts
                   to establishing and building trust and a participative team approach where peers support 
                   and collaborate with each other to achieve common goals and thrive, even in times of adversity
                </li>
              </ul>
            </div>
        </div>
        <div className="container mx-auto px-6 md:px-10 lg:px-16 mb-5">
            <div className="flex flex-col mt-5 mb-5 items-center"> 
                <div className="text-4xl self-center">
                  <h2 className="font-heading text-teal-900">My Coaching Process</h2>
                </div>
                <div className="w-full sm:w-3/4 md:w-3/4 lg:w-1/2 xl:w-1/2">
                  <Img 
                    fluid={infoGraphicFluid} 
                    alt="Angela Cooney - Executive Coach Process - Dublin - Ireland" 
                  />
                </div>
            </div>
        </div>
      </div>

    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "services-page-compass-banner.jpg"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      infoGraphic: file(relativePath: { eq: "coach-process-info-graphic-min.png"}) {
        childImageSharp {
          fluid( maxWidth: 320, quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
  }
`;


export default ExecutiveTeamCoaching;
